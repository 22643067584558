html,
body {
  color: #fff;
  height: 100%;
  margin: 0;
}

.wrap {
  background-image: url("http://assets.sheppard.io/bg/watercolor.jpg");
  background-size: cover;
  height: 100%;
  width: 100%;
}

.content {
  background-color: #3e0f54;
  filter: opacity(.8);
  height: 100%;
}

.icons {
  font-size: 24px;
  color: #fff;
}

.logo {
  padding: 100px;
  width: 800px;

  img {
    width: 100%;
  }
}

.kraken {
  background-image: url("http://assets.sheppard.io/png/sheppard_ruby_kraken_600x600.png");
  background-repeat: no-repeat;
  position: absolute;
  bottom: 0;
  height: 600px;
  right: 0;
  width: 600px;
}

.kraken-logo {
  background-image: url("http://assets.sheppard.io/svg/defs.svg#krak");
  fill: white;
  height: 45px;
  width: 159px;
}

.star-five {
  margin: 50px 0;
  position: relative;
  display: block;
  color: black;
  width: 0px;
  height: 0px;
  border-right: 100px solid transparent;
  border-bottom: 70px solid black;
  border-left: 100px solid transparent;
  transform: rotate(35deg);

  :before {
    border-bottom: 80px solid black;
    border-left: 30px solid transparent;
    border-right: 30px solid transparent;
    position: absolute;
    height: 0;
    width: 0;
    top: -45px;
    left: -65px;
    display: block;
    content: '';
    transform: rotate(-35deg);
  }

  :after {
    position: absolute;
    display: block;
    color: black;
    top: 3px;
    left: -105px;
    width: 0px;
    height: 0px;
    border-right: 100px solid transparent;
    border-bottom: 70px solid black;
    border-left: 100px solid transparent;
    transform: rotate(-70deg);
    content: '';
  }
}