/*******************
 Box Sizing
 *******************/
html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

/****************************
 Generic Margins and Paddings
 ****************************/
body,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
li,
p,
pre,
blockquote,
figure,
hr {
  margin: 0;
  padding: 0;
}

/*******************
 Lists
 *******************/
ul {
  list-style: none;
}

/*******************
 Forms and buttons
 *******************/
input,
textarea,
select,
button {
  color: inherit;
  font: inherit;
  letter-spacing: inherit;
}

/* I usually expand input and textarea to full-width */
input[type="text"],
textarea {
  width: 100%;
}

/* More friendly border */
input,
textarea,
button {
  border: 1px solid gray;
}

/* Some defaults for one-liner buttons */
button {
  padding: 0.75em 1em;
  border-radius: 0;
  line-height: 1;
  background-color: transparent;
}

button * {
  pointer-events: none;
}

/***********************************
 Easy responsive for media elements
 ***********************************/
img,
svg,
iframe,
video,
object,
embed {
  display: block;
  max-width: 100%;
}

/*******************
 Useful table styles
 *******************/
table {
  table-layout: fixed;
  width: 100%;
}

/*******************
 The hidden attribute
 *******************/
[hidden] {
  display: none !important;
}

/*******************
 Noscript
 *******************/
noscript {
  display: block;
  margin-bottom: 1em;
  margin-top: 1em;
}
